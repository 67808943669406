<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section>
                <v-section-heading>
                    Navigation
                </v-section-heading>

                <v-section-links :menu="menu" />
            </v-section>
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-model-form :id="$route.params.user" module="userModule">
                <template v-slot:default="{ model, refreshModel }">
                    <v-section>
                        <v-section-heading>
                            <template v-slot:default>
                                {{ model.name }} - {{ $route.meta.title }}
                            </template>

                            <template v-slot:description>
                                {{ model.email }}
                            </template>

                            <template v-slot:actions>
                                <div class="flex flex-shrink-0 space-x-default items-center">
                                    <template v-if="model.conversation">
                                        <v-button color="dark" :to="{ name: 'conversations.show', params: { conversation: model.conversation.conversation_id } }">
                                            View Conversation
                                        </v-button>
                                    </template>
                                    <template v-else>
                                        <v-button color="dark" @click="createConversation(model.user_id)">
                                            Start Conversation
                                        </v-button>
                                    </template>
                                    <v-button color="dark" @click="impersonateUser(model.user_id)">
                                        <v-icon class="fa fa-user-secret text-secondary-500 text-sm" />
                                    </v-button>
                                </div>
                                <div class="block lg:hidden">
                                    <v-button color="primary" @click.stop="toggleSidebar">
                                        <i class="fa fa-list-ul" />
                                    </v-button>
                                </div>
                            </template>
                        </v-section-heading>

                        <v-ghost :user="model" @user-updated="refreshModel" />
                    </v-section>
                </template>
            </v-model-form>
        </template>
    </v-main>
</template>

<script>
import AuthService from "@/services/modules/auth-service";
import ConversationService from "@/services/modules/conversation-service";

export default {
    data() {
        return {
            menu: [
                {
                    label: "Summary",
                    route: { name: "users.show.summary" },
                    description: "Manage the user's basic information and contact details.",
                    icon: "fa fa-id-card",
                },
                {
                    label: "Activity Log",
                    route: { name: "users.show.activity-log" },
                    description: "All the changes the user has done to the application.",
                    icon: "fa fa-list-alt",
                },
                {
                    label: "Games",
                    route: { name: "users.show.games" },
                    description: "The games the user has access to in the application.",
                    icon: "fa fa-gamepad",
                },
                {
                    label: "Jobs",
                    route: { name: "users.show.jobs" },
                    description: "All the jobs generated from orders created by the user.",
                    icon: "fa fa-business-time",
                },
                {
                    label: "Notifications",
                    route: { name: "users.show.notifications" },
                    description: "All the notifications available to the user.",
                    icon: "fa fa-bell",
                },
                {
                    label: "Orders",
                    route: { name: "users.show.orders" },
                    description: "The orders created by the user through the main store.",
                    icon: "fa fa-shopping-cart",
                },
                {
                    label: "Organizations",
                    route: { name: "users.show.organizations" },
                    description: "The organizations the user has access to.",
                    icon: "fa fa-store",
                },
                {
                    label: "Payout Information",
                    route: { name: "users.show.payout-information" },
                    description: "Information related to how the user receives payouts.",
                    icon: "fab fa-paypal",
                },
                {
                    label: "Payouts",
                    route: { name: "users.show.payouts" },
                    description: "The payouts generated from the user's completed jobs.",
                    icon: "fa fa-credit-card",
                },
                {
                    label: "Security",
                    route: { name: "users.show.security" },
                    description: "Manage the user's password, roles, user type, and options.",
                    icon: "fa fa-fingerprint",
                },
                {
                    label: "User Logins",
                    route: { name: "users.show.user-logins" },
                    description: "All the instances the user has logged into the application.",
                    icon: "fa fa-clock",
                },
            ],
            conversationService: ConversationService,
        };
    },
    methods: {
        createConversation(userId) {
            this.conversationService.store({ user_id: userId })
                .catch((error) => {
                    alert(error.response.data.message);
                }).then((response) => {
                    this.$router.push({ name: "conversations.show", params: { conversation: response.data.data.conversation_id } });
                });
        },
        impersonateUser(userId) {
            this.toggleLoading();

            AuthService.impersonateUser(userId)
                .catch((error) => {
                    alert(error.response.data.message);
                })
                .then((response) => {
                    localStorage.setItem("impersonation_token", response.data.impersonation_token);
                })
                .finally(() => {
                    this.toggleLoading();

                    // cannot use router push because page needs to reload to refresh user
                    window.location.href = this.$router.resolve({ name: "home" }).href;
                });
        },
    },
};
</script>
